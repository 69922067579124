import { Injectable, signal } from '@angular/core';
import { delay } from '@azavista/components/shared';

@Injectable({
  providedIn: 'root'
})
export class FullScreenElementService {
    isFullScreenMode = signal(false);

    async toggleFullScreenMode(forceValue?: boolean) {
        const newValue = !!(forceValue ?? !this.isFullScreenMode());
        if (newValue !== this.isFullScreenMode()) {
            // delay is required to avoid `Expressionchangedafterithasbeencheckederror`
            await delay(0);
            this.isFullScreenMode.set(newValue);
        }
    }
}
